var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('breadcrumb',[_c('img',{staticClass:"breadcrumb-image",attrs:{"src":"static/img/appetite.svg"}}),_c('breadcrumb-item',[_c('router-link',{attrs:{"to":"/appetites"}},[_vm._v("Qualitative Settings")])],1),(_vm.add)?_c('breadcrumb-item',{attrs:{"active":""}},[_vm._v(" Add Qualitative Settings ")]):_vm._e(),(_vm.add == false)?_c('breadcrumb-item',{attrs:{"active":""}},[_vm._v(" Edit Qualitative Settings ")]):_vm._e()],1),_c('form',{attrs:{"autocomplete":"off"}},[(_vm.loaded)?_c('card',[_c('div',[_c('div',{staticClass:"card-title",staticStyle:{"font-size":"18px","margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.model.name)+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"label":"Percentile for Estimation","error":_vm.getError('percentile')}},[_c('el-input-number',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  decimal: true,
                  between: [1, 100],
                }),expression:"{\n                  required: true,\n                  decimal: true,\n                  between: [1, 100],\n                }"}],attrs:{"max":100,"min":1,"name":"percentile"},model:{value:(_vm.model.percentile),callback:function ($$v) {_vm.$set(_vm.model, "percentile", $$v)},expression:"model.percentile"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"label":"Very High","error":_vm.getError('very high')}},[_c('el-input-number',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  min_value: _vm.model.high,
                  decimal: true,
                  between: [1, 100],
                }),expression:"{\n                  required: true,\n                  min_value: model.high,\n                  decimal: true,\n                  between: [1, 100],\n                }"}],attrs:{"max":100,"min":1,"name":"very high"},model:{value:(_vm.model.veryHigh),callback:function ($$v) {_vm.$set(_vm.model, "veryHigh", $$v)},expression:"model.veryHigh"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"label":"High","error":_vm.getError('high')}},[_c('el-input-number',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  max_value: _vm.model.veryHigh,
                  min_value: _vm.model.medium,
                  decimal: true,
                  between: [1, 100],
                }),expression:"{\n                  required: true,\n                  max_value: model.veryHigh,\n                  min_value: model.medium,\n                  decimal: true,\n                  between: [1, 100],\n                }"}],attrs:{"max":100,"min":1,"name":"high"},model:{value:(_vm.model.high),callback:function ($$v) {_vm.$set(_vm.model, "high", $$v)},expression:"model.high"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"label":"Medium","error":_vm.getError('medium')}},[_c('el-input-number',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  max_value: _vm.model.high,
                  min_value: _vm.model.low,
                  decimal: true,
                  between: [1, 100],
                }),expression:"{\n                  required: true,\n                  max_value: model.high,\n                  min_value: model.low,\n                  decimal: true,\n                  between: [1, 100],\n                }"}],attrs:{"max":100,"min":1,"name":"medium"},model:{value:(_vm.model.medium),callback:function ($$v) {_vm.$set(_vm.model, "medium", $$v)},expression:"model.medium"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"label":"Low","error":_vm.getError('low')}},[_c('el-input-number',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  max_value: _vm.model.medium,
                  min_value: _vm.model.veryLow,
                  decimal: true,
                  between: [1, 100],
                }),expression:"{\n                  required: true,\n                  max_value: model.medium,\n                  min_value: model.veryLow,\n                  decimal: true,\n                  between: [1, 100],\n                }"}],attrs:{"max":100,"min":1,"name":"low"},model:{value:(_vm.model.low),callback:function ($$v) {_vm.$set(_vm.model, "low", $$v)},expression:"model.low"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"label":"Very Low","error":_vm.getError('very low')}},[_c('el-input-number',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  max_value: _vm.model.low,
                  decimal: true,
                  between: [1, 100],
                }),expression:"{\n                  required: true,\n                  max_value: model.low,\n                  decimal: true,\n                  between: [1, 100],\n                }"}],attrs:{"max":100,"min":1,"name":"very low"},model:{value:(_vm.model.veryLow),callback:function ($$v) {_vm.$set(_vm.model, "veryLow", $$v)},expression:"model.veryLow"}})],1)],1)])]),_c('div',{staticClass:"card-footer text-right"},[_c('button',{staticClass:"btn btn-fill btn-danger btn-wd",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fa fa-times"})]),_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-fill btn-info btn-wd",attrs:{"type":"submit"},on:{"click":[function($event){$event.preventDefault();return _vm.validate($event)},_vm.save]}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fa fa-save"})]),_vm._v(" Save ")])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }