<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/appetite.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/appetites">Qualitative Settings</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add" active> Add Qualitative Settings </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Qualitative Settings
      </breadcrumb-item>
    </breadcrumb>
    <form autocomplete="off">
      <card v-if="loaded">
        <div>
          <div class="card-title" style="font-size: 18px; margin-bottom: 10px">
            {{ model.name }}
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input
                label="Percentile for Estimation"
                :error="getError('percentile')"
              >
                <el-input-number
                  v-model="model.percentile"
                  :max="100"
                  :min="1"
                  v-validate="{
                    required: true,
                    decimal: true,
                    between: [1, 100],
                  }"
                  name="percentile"
                ></el-input-number>
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="Very High" :error="getError('very high')">
                <el-input-number
                  v-model="model.veryHigh"
                  :max="100"
                  :min="1"
                  v-validate="{
                    required: true,
                    min_value: model.high,
                    decimal: true,
                    between: [1, 100],
                  }"
                  name="very high"
                ></el-input-number>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input label="High" :error="getError('high')">
                <el-input-number
                  v-model="model.high"
                  :max="100"
                  :min="1"
                  v-validate="{
                    required: true,
                    max_value: model.veryHigh,
                    min_value: model.medium,
                    decimal: true,
                    between: [1, 100],
                  }"
                  name="high"
                ></el-input-number>
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="Medium" :error="getError('medium')">
                <el-input-number
                  v-model="model.medium"
                  :max="100"
                  :min="1"
                  v-validate="{
                    required: true,
                    max_value: model.high,
                    min_value: model.low,
                    decimal: true,
                    between: [1, 100],
                  }"
                  name="medium"
                ></el-input-number>
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input label="Low" :error="getError('low')">
                <el-input-number
                  v-model="model.low"
                  :max="100"
                  :min="1"
                  v-validate="{
                    required: true,
                    max_value: model.medium,
                    min_value: model.veryLow,
                    decimal: true,
                    between: [1, 100],
                  }"
                  name="low"
                ></el-input-number>
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input label="Very Low" :error="getError('very low')">
                <el-input-number
                  v-model="model.veryLow"
                  :max="100"
                  :min="1"
                  v-validate="{
                    required: true,
                    max_value: model.low,
                    decimal: true,
                    between: [1, 100],
                  }"
                  name="very low"
                ></el-input-number>
              </fg-input>
            </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <button
            type="button"
            @click="close"
            class="btn btn-fill btn-danger btn-wd"
          >
            <span class="btn-label">
              <i class="fa fa-times"></i>
            </span>
            Cancel
          </button>
          <button
            type="submit"
            @click.prevent="validate"
            @click="save"
            class="btn btn-fill btn-info btn-wd"
          >
            <span class="btn-label">
              <i class="fa fa-save"></i>
            </span>
            Save
          </button>
        </div>
      </card>
    </form>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      model: {
        id: 0,
        name: "",
        percentile: 0,
        veryHigh: 0,
        high: 0,
        medium: 0,
        low: 0,
        veryLow: 0,
        isActive: true,
      },
      modelValidations: {
        percentile: {
          required: true,
          decimal: true,
          between: [1, 100],
        },
      },
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.appetite, isValid);
      });
    },
    get(id) {
      if (id == 0) {
        self.$router.push("/appetites");
      }

      let self = this;

      self.$store.state.services.appetiteService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.name = r.data.name;
          self.model.percentile = r.data.percentile;
          self.model.veryHigh = r.data.veryHigh;
          self.model.high = r.data.high;
          self.model.medium = r.data.medium;
          self.model.low = r.data.low;
          self.model.veryLow = r.data.veryLow;
          self.model.isActive = r.data.isActive;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          if (self.model.id > 0) {
            self.$store.state.services.appetiteService
              .update(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    close(){
      let self = this;
      self.$router.push("/appetites");
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Qualitative settings saved</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>